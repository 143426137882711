import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import WhatsUnique from "../../../components/Landers/WhatsUnique";

const steps = [
  {
    h5: "Get the reference’s details from the candidate",
    p:
      "Request the candidate to provide the personal information of the reference like name, contact details, location, and designation.",
  },
  {
    h5: "Check the reference’s credibility",
    p:
      "Before approaching the said reference - their current employer, relationship with the candidate, and the duration of the association are reviewed.",
  },
  {
    h5: "Get to know your applicants on a personal level",
    p:
      "A telephonic interview surrounding the candidate’s integrity, performance, abilities, or skills is held to ensure the authenticity of the information obtained. ",
  },
];

const testimonials = [
  {
    name: "Pushpita G ",
    company: "EZOPS Technologies India Pvt. Ltd.",
    p: `I'm already in love with the UX. It's extremely user-friendly and self-explanatory. All you have to do is to
			add the candidate and it takes care of the rest. Right from running checks to real-time updates, flags, and reminders -
			SpringVerify does the job like a breeze.`,
  },
  {
    name: "Nandita N",
    company: "Umlaut Pvt. Ltd.",
    p: `SpringVerify is a true all-rounder when it comes to background verifications. Most services I've used in the past have
			always had compromises. But SpringVerify has been exceptional and impressive - right from the variety of checks it offers,
			its reliablity to 24x7 customer support.`,
  },
  {
    name: "Purushottam N",
    company: "Speegile Consulting",
    p: `No doubt SpringVerify has transformed the way we run background checks. I love the variety of checks it offers
			that delivers accurate results in a quick and efficient manner.`,
  },
];

export default () => (
  <div className={`${styles.solutions} ${styles.ref}`}>
    <SEO
      title="Best Reference Verification Service | SpringVerify India"
      desc="Confirm your candidate's ability and skill levels with SpringVerify's leading background verification service in India."
      url="https://in.springverify.com/screenings/reference-verification/"
      imgurl="https://assets-sv-in.gumlet.io/test-pages/SV-IN+(reference+check).jpg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1="Professional Reference Verifications for Streamlined Hiring"
        p="Go beyond what is stated on your candidate’s resume with reference checks. Get deeper insight into information like work ethic, professional capabilities, and attitude by contacting the reference personally. "
        img={"https://assets-sv-in.gumlet.io/LP/Reference/banner.png"}
      />
    </div>
    <Brands
      list={["airmeet", "civica", "indorama", "inshorts", "rently", "simpl"]}
    />
    <section>
      <SectionTemplate
        img="Reference/need.svg"
        h2="Importance of \nReference Checks"
        p={`86% of candidates lie on their resumes. With reference checks, you can gain information about the applicant on a personal level by contacting the reference provided. This can save you time, money, and help you make better hiring decisions. With reference checks you can:`}
        ul={[
          "Verify your candidate’s integrity",
          "Get deeper insights into their performance, skill levels, and ability",
          "Know about their reputation and trustworthiness ",
          "Find out if they really are a right “fit”",
        ]}
      />
    </section>
    <section>
      <SectionTemplate
        reversed
        img="Reference/using.svg"
        h2="How do Reference Checks happen in SpringVerify?"
        p={`The reference is asked a set of standardized questions such as:`}
      >
        <div className={styles.using}>
          {[
            "What was your relationship with the candidate?",
            "How long have you worked with him/her?",
            "How would you describe his/her interpersonal skills?",
            "What would you say are his/her strongest attributes?",
            "Would you recommend the candidate for rehire?",
            "Is there anything you would like to add?",
          ].map((e, i) => (
            <div>
              <img
                data-src={`https://assets-sv-in.gumlet.io/LP/Reference/Using/${
                  i + 1
                }.svg`}
                alt=""
              />
              <p>{e}</p>
            </div>
          ))}
        </div>
      </SectionTemplate>
    </section>
    <ThreeSteps
      steps={steps}
      candidate="https://assets-sv-in.gumlet.io/LP/Reference/candidate.png"
      path="Reference"
    />
    <WhatsUnique
      path="Employment"
      info={[
        "Easy onboarding",
        "Safe and secure",
        "Transparent pricing",
        "Workflow automation",
        "Remote-friendly and contactless verifications",
        "Compliant with ISO 27001:9000, PCI, and more",
        "Tailored solutions based on your requirements",
        "Powered by AI and Blockchain",
      ]}
    />
    <Testimonials testimonials={testimonials} path="Global" />
    <GetStarted
      img="Reference/get-started.svg"
      h3="Ready to get started with us?"
      p="Gain insights about your candidate's skill level and expertise with Reference Verifications"
    />
    <Footer />
  </div>
);
